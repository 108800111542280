<template>
  <div v-if="form.inactivity" class="list" :name="$options.name">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.inactivityStep.title") }}</span>
        </span>
      </span>
    </h3>
    <b-overlay
      :show="form.inactivity['closeDatetime'] !== undefined"
      rounded="sm"
      blur="none"
      z-index="100"
      :key="'inactivity-' + form.inactivity['closeDatetime']"
    >
      <template #overlay>
        <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
        <p class="redMessage">{{ $t("closeMsg") }}</p>
        <p class="redMessage">
          {{ $t("closeMsgDate") }}
          {{ $showDate(form.inactivity["closeDatetime"]) }}
        </p>
      </template>
      <fieldset v-if="form.inactivity?.details?.length > 0">
        <h4 class="level2">
          {{ $t("editTrip.inactivityStep.info") }}
          <span
            v-b-modal="'modal-inactivity-remark'"
            class="add-remark-btn-title"
            v-show="form.inactivity['closeDatetime'] === undefined"
          ></span>
        </h4>
        <Popup
          v-model="form.inactivity['remark']"
          :title-label="$t('editTrip.remarkTitle')"
          :maxlength="2000"
          :modalName="'modal-inactivity-remark'"
        />
        <b-row>
          <b-col lg="4" sm="6" cols="12">
            <LabelView
              :value="form.inactivity['reportUID']"
              :floatLeft="true"
              type="text"
              :label="$t('editTrip.inactivityStep.reportUID')"
            />
          </b-col>
        </b-row>
      </fieldset>
      <InactivityForm
        v-model="form.inactivity"
        :addErrorToParent="addError"
        :refreshError="refreshError"
        :value="form.inactivity['reportUID']"
      />

      <fieldset class="suppEntryButtonWrapper removeTopMargin">
        <p>
          <input
            v-if="!form.inactivity['closeDatetime']"
            @click="closeInactivity()"
            class="closeSuppEntryButton"
            type="button"
            :value="$t('editTrip.inactivityStep.close')"
          />
        </p>
      </fieldset>
    </b-overlay>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InactivityForm from "@/components/subformEditTrip/InactivityForm.vue";
import LabelView from "@/components/LabelView.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  name: "InactivityStep",
  components: {
    InactivityForm,
    Popup,
    LabelView
  },
  data() {
    return {
      form: {
        inactivity: {}
      },
      refreshError: 0,
      pageName: "inactivityStep"
    };
  },
  computed: {
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      currentPage: state => state.editTripSubform.currentPage,
      showError: state => state.editTripSubform.showError
    })
  },
  watch: {
    showError() {
      if (this.isFinalReview || this.currentPage === this.pageName) {
        ++this.refreshError;
      }
    },
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    }
  },
  beforeMount() {
    this.form.inactivity = this.openTrip.inactivity ?? {};
  },
  methods: {
    ...mapActions(["addErrorsToState", "closeObject"]),
    addError(error) {
      this.addErrorsToState({ page: this.pageName, errors: error });
    },
    closeInactivity() {
      const vm = this;
      this.refreshError++;
      this.closeObject({
        value: this.form.inactivity,
        type: this.pageName,
        index: null,
        vm: vm
      });
    }
  }
};
</script>
