import { render, staticRenderFns } from "./ModulesOrder.vue?vue&type=template&id=7d4d5065&scoped=true"
import script from "./ModulesOrder.vue?vue&type=script&lang=js"
export * from "./ModulesOrder.vue?vue&type=script&lang=js"
import style0 from "./ModulesOrder.vue?vue&type=style&index=0&id=7d4d5065&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d4d5065",
  null
  
)

export default component.exports