<template>
  <div :name="$options.name">
    <b-overlay
      :key="'inactivity' + inactivity['closeDatetime']"
      rounded="sm"
      blur="none"
    >
      <fieldset
        v-for="(detail, index) in inactivity.details"
        :key="'detail-' + index"
        class="tow"
      >
        <h4 class="level1">
          {{ $t("editTrip.inactivityStep.period") }}&nbsp;{{ index + 1 }}
          <span
            v-show="inactivity['closeDatetime'] === undefined"
            v-b-modal="'modal-detail-remark' + index"
            class="add-remark-btn-title"
          ></span>
          <Popup
            v-model="detail['remark']"
            :title-label="$t('editTrip.remarkTitle')"
            :maxlength="2000"
            :modalName="'modal-detail-remark' + index"
          />
        </h4>
        <fieldset :disabled="inactivity['closeDatetime'] !== undefined">
          <GenericInput
            v-model="detail['licenceNumber']"
            :digits="true"
            :key="'inactivity-licence-' + index"
            :label="$t('editTrip.effortStep.licenceNumber')"
            :maxlength="$const.LIC_NO_MAX_LENGTH"
            :minlength="4"
            :min="1000"
            :options="operatorsFin"
            :otherValue="true"
            :refeshError="refreshError + inactivityFormRefresh"
            :required="true"
            type="datalist"
            @error="addError"
          />
          <br />
          <DateInput
            v-model="detail['start']"
            :autodatetime="true"
            :label="$t('editTrip.inactivityStep.start')"
            :refeshError="refreshError + inactivityFormRefresh"
            :required="true"
            @error="addError"
          />
          <DateInput
            v-model="detail['end']"
            :autodatetime="true"
            :label="$t('editTrip.inactivityStep.end')"
            :refeshError="refreshError + inactivityFormRefresh"
            :required="true"
            :minDates="[
              {
                value: detail['start'],
                text: $t('editTrip.inactivityStep.afterStartDate')
              }
            ]"
            @error="addError"
          />
          <GenericInput
            v-model="detail['reason']"
            :label="$t('editTrip.inactivityStep.reason')"
            :maxlength="2000"
            :refeshError="refreshError + inactivityFormRefresh"
            :required="true"
            type="text"
            @error="addError"
          />
        </fieldset>
      </fieldset>
      <fieldset class="suppEntryButtonWrapper removeBottomPadding">
        <p>
          <input
            v-if="inactivity.details?.length > 1 && !checkCloseDate"
            :value="
              $t('editTrip.inactivityStep.removeDetail') +
                String(inactivity.details.length)
            "
            class="removeSuppEntryButton"
            type="button"
            @click="removeReport()"
          />
          <input
            v-if="!checkCloseDate"
            :class="inactivity.details?.length > 0 ? 'removeTopMargin' : null"
            :value="$t('editTrip.inactivityStep.addDetail')"
            class="addSuppEntryButton removeBottomMargin"
            type="button"
            @click="addReport()"
          />
        </p>
      </fieldset>
    </b-overlay>
  </div>
</template>
<script>
import { mapState } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import DateInput from "@/components/widgets/DateInput.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  name: "InactivityForm",
  components: {
    GenericInput,
    DateInput,
    Popup
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    refreshError: {
      type: Number,
      default: 0
    },
    addErrorToParent: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      inactivity: {
        details: []
      },
      error: {},
      firstTime: false,
      inactivityFormRefresh: 0,
      name: "inactivityStep"
    };
  },
  computed: {
    ...mapState({
      currentPage: state => state.editTripSubform.currentPage,
      operatorsFin: state => state.editTripSubform.operatorsFin,
      openTrip: state => state.currentOpenTrip,
      stateErrors: state => state.editTripSubform.errors
    }),
    checkCloseDate() {
      if (this.inactivity && this.inactivity !== null) {
        if (
          this.inactivity.closeDatetime &&
          this.inactivity.closeDatetime !== null
        ) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    currentPage() {
      if (this.openTrip?.creation && this.currentPage === this.name) {
        if (this.inactivity?.details?.length === 0) {
          this.addReport();
        }
      }
    },
    inactivity: {
      deep: true,
      handler: function() {
        if (!this.firstTime) {
          this.$emit("input", this.inactivity);
        } else {
          this.firstTime = false;
        }
      }
    },
    value: {
      deep: true,
      handler: function() {
        if (this.value && this.value.details) {
          this.inactivity = this.value;
        }
      }
    }
  },
  mounted() {
    this.inactivity = { ...this.inactivity, ...this.value };
    if (!this.openTrip?.logbookUID && this.inactivity?.details?.length === 0)
      this.addReport();
  },
  methods: {
    removeReport() {
      this.inactivity.details = this.inactivity.details.slice(0, -1);
      this.inactivity = JSON.parse(JSON.stringify(this.inactivity));
    },
    async addReport() {
      this.error = {};
      this.inactivityFormRefresh++;

      await this.$nextTick();
      const errorsFieldsNames = keysMatchingValue(this.stateErrors, true);

      if (errorsFieldsNames.length > 0 || this.$hasInputErrorAndScroll()) {
        scrollToTopField(errorsFieldsNames);
        return;
      }

      const now = new Date();
      this.inactivity.details.push({
        start: null,
        end: null,
        licenceNumber:
          this.operatorsFin && this.operatorsFin.length === 1
            ? this.operatorsFin[0].text
            : null,
        reason: null,
        creation: now.toISOString()
      });

      if (this.inactivity.details.length === 1) {
        this.inactivity["creation"] = now.toISOString();
        this.inactivity["reportUID"] = this.$makeAlphaId(6);
      }
      this.inactivity = JSON.parse(JSON.stringify(this.inactivity));
    },
    addError(error) {
      this.error = { ...this.error, ...error };
      this.addErrorToParent(error);
    }
  }
};
</script>
